<template>
  <section class="page-content">
    <p class="description">
      W zakładce <strong>Moje ogłoszenia na wydarzeniu</strong> przygotuj
      oferty, które chcesz zamieścić w dotykowych ekranach na wydarzeniu i
      wybierz kategorię w której mają wyświetlać się ogłoszenia.
    </p>
    <download-data page-type="_vj_" v-if="!disabled && maxAds > 0" @success="prepareData" />
    <h2>Ogłoszenia na wydarzeniu:</h2>
    <p>
      <span class="blue-text">Pakiet CLASSIC</span> – możliwość dodania 10
      ogłoszeń
    </p>
    <p>
      <span class="blue-text">Pakiet PRO</span> – możliwość dodania 15 ogłoszeń
    </p>
    <p>
      <span class="blue-text">Pakiet ELITE</span> – możliwość dodania 20
      ogłoszeń
    </p>
    <p>
      <span class="blue-text">Pakiet ENTERPRISE</span> – możliwość dodania
      ogłoszeń bez ograniczeń
    </p>
    <div
      v-for="(ad, index) in formData._vj_ads_list"
      :key="index"
      class="margin-bt-5"
    >
      <h2 class="margin-bt-1">Dodaj ogłoszenie {{ index + 1 }}</h2>
      <h3>Nazwa stanowiska w ogłoszeniu</h3>
      <v-input
        :id="`_vj_position_${ad + 0}`"
        :name="`_vj_position_${ad + 0}`"
        label="Prosimy o wpisanie nazwy stanowiska"
        :required="true"
        :disabled="disabled"
        css-variant="special-label"
        v-model="formData[`_vj_position_${ad + 0}`]"
        @auto-save="sendData(false)"
      />

      <h3 v-if="!formData[`_vj_offer_url_checkbox_${ad + 0}`]">
        Link do ogłoszenia na pracuj.pl
      </h3>
      <v-input
        v-if="!formData[`_vj_offer_url_checkbox_${ad + 0}`]"
        :id="`_vj_offer_url_${ad + 0}`"
        :name="`_vj_offer_url_${ad + 0}`"
        label="Prosimy o podanie linku do ogłoszenia na pracuj.pl"
        :required="false"
        :disabled="disabled"
        css-variant="special-label"
        v-model="formData[`_vj_offer_url_${ad + 0}`]"
        type="url"
        @auto-save="sendData(false)"
      />
      <v-checkbox-slider
        :id="`_vj_offer_url_checkbox_${ad + 0}`"
        :name="`_vj_offer_url_checkbox_${ad + 0}`"
        label="Chcę wgrać plik PDF z ogłoszeniem"
        :required="false"
        @auto-save="sendData(false)"
        :disabled="disabled"
        v-model="formData[`_vj_offer_url_checkbox_${ad + 0}`]"
      />
      <div
        v-show="
          formData[`_vj_offer_url_checkbox_${ad + 0}`] ||
          formData[`_vj_advertisement_${ad + 0}`]
        "
      >
        <h3 class="margin-tp-2">Plik ogłoszenia</h3>
        <v-upload
          :id="`_vj_advertisement_${ad + 0}`"
          :name="`_vj_advertisement_${ad + 0}`"
          label="Załaduj ogłoszenie"
          label-alt="Zmień ogłoszenie"
          file-type="pdf"
          confirmMessage="Potwierdź załadowanie ogłoszenia"
          :edition="activeEdition"
          :company="companyData.id"
          :disabled="disabled"
          v-model="formData[`_vj_advertisement_${ad + 0}`]"
          :detailId="getDetailId(`_vj_advertisement_${ad + 0}`)"
        />
        <span class="informations">
          Prosimy o załadowanie ogłoszenia w formacie PDF. Maksymalna waga pliku
          to 5 MB.
        </span>
      </div>

      <h3 class="margin-tp-4 margin-bt-0">Kategorie ogłoszenia</h3>
      <tags-select
        :tags-header="`Wybierz maksymalnie dwie kategorie, w których ma wyświetlać się ogłoszenie ${
          index + 1
        }`"
        :tags-list="tagsList"
        :tags-limit="2"
        :name="`_vj_category_${ad + 0}`"
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData[`_vj_category_${ad + 0}`]"
      />
      <main-btn
        :disabled="disabled"
        @click="removeAd(ad)"
        class="margin-bt-8 margin-tp-2"
        :content="'Usuń ogłoszenie'"
        :gray="true"
      />
    </div>
    <p>
      Maksymalna liczba ogłoszeń:
      <span class="blue-text">{{ this.maxAds }}</span>
    </p>
    <main-btn
      v-if="formData._vj_ads_list.length < maxAds"
      @click="addAd"
      :disabled="disabled"
      class="margin-bt-2"
      :content="'Dodaj kolejne ogłoszenie'"
    />
    <div v-if="!disabled" class="send-btn-cont">
      <main-btn
        :content="'Zapisz wersję roboczą'"
        :tooltip="'Wersja robocza umożliwia Ci naniesienie zmian i edytowanie tej zakładki. Pamiętaj aby jak najszybciej zapisać wersję ostateczną materiałów, która zostanie przesłana do organizatora wydarzenia.'"
        :submit="true"
        :saving="loading"
        :gray="true"
        @click="sendData(false)"
      />
      <main-btn
        :content="'Zapisz wersję ostateczną'"
        :tooltip="'Wersja ostateczna to finalna informacja jaka zostanie przesłana do Pracuj.pl na potrzeby organizacji wydarzenia'"
        :submit="true"
        :saving="loading"
        @click="saveData"
      />
    </div>
    <div v-else>
      <p>
        <strong
          >Ustawienia zakładki zostały przekazane do Pracuj.pl. Jeśli chcesz
          dokonać zmian skontaktuj się pod adresem
          <a class="important-info" :href="`mailto:${companyGuardian.email}`">{{
            companyGuardian.email
          }}</a></strong
        >
      </p>
    </div>
    <p class="text-center margin-tp-1" v-show="message">
      <strong :class="`message--${messageType}`">{{ message }}</strong>
    </p>
  </section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import sendDataAndDispatch from "@/mixins/sendDataAndDispatch.js";
import assignDataFromDatabase from "@/mixins/assignDataFromDatabase.js";
import getDetailId from "@/mixins/getDetailId.js";

export default Vue.extend({
  mixins: [sendDataAndDispatch, assignDataFromDatabase, getDetailId],
  data() {
    return {
      loading: false,
      message: "",
      messageType: "ok",
      disabled: false,
      tagsList: [
        "Administracja biurowa",
        "Badania i rozwój",
        "Bankowość",
        "BHP / Ochrona środowiska",
        "Budownictwo",
        "Call Center",
        "Doradztwo / Konsulting",
        "Edukacja / Szkolenia",
        "Energetyka",
        "Finanse / Ekonomia",
        "Franczyza / Własny biznes",
        "Hotelarstwo / Gastronomia / Turystyka",
        "Human Resources / Zasoby ludzie",
        "Internet / e-Commerce / Nowe media",
        "Inżynieria",
        "IT – Administracja",
        "IT – Rozwój oprogramowania",
        "Łańcuch dostaw",
        "Kontrola jakości",
        "Marketing",
        "Media / Sztuka / Rozrywka",
        "Nieruchomości",
        "Obsługa klienta",
        "Praca fizyczna",
        "Prawo",
        "Produkcja",
        "Public Relations",
        "Reklama / Grafika / Kreacja / Fotografia",
        "Sektor publiczny",
        "Sprzedaż",
        "Transport / Spedycja",
        "Ubezpieczenia",
        "Zakupy",
        "Zdrowie / Uroda / Rekreacja",
        "Inne",
      ],
      maxAds: 0,
      formData: {
        _vj_disabled: false,
        _vj_ads_list: [],
      },
      ignoredKeys: [],
    };
  },
  methods: {
    getNewAdId() {
      if (this.formData._vj_ads_list.length === 0) {
        return 1;
      } else {
        return Math.max(...this.formData._vj_ads_list) + 1;
      }
    },
    addAd() {
      const newAdId = this.getNewAdId();

      this.formData._vj_ads_list.push(newAdId);
      this.formData[`_vj_advertisement_${newAdId}`] = "";
      this.formData[`_vj_position_${newAdId}`] = "";
      this.formData[`_vj_category_${newAdId}`] = [];
      this.formData[`_vj_offer_url_${newAdId}`] = "";
      this.formData[`_vj_offer_url_checkbox_${newAdId}`] = "";
      this.sendData(false);
    },
    removeAd(ad) {
      this.formData._vj_ads_list = this.formData._vj_ads_list.filter(
        (e) => e !== ad
      );
      this.formData[`_vj_advertisement_${ad}`] = undefined;
      this.formData[`_vj_category_${ad}`] = undefined;
      this.formData[`_vj_offer_url_${ad}`] = undefined;
      this.formData[`_vj_offer_url_checkbox_${ad}`] = undefined;
      this.sendData(false);
    },
    setMaximumAdNumber() {
      if (this.currentCompanyPackage.name === "Classic") {
        this.maxAds = 10;
      } else if (this.currentCompanyPackage.name === "Pro") {
        this.maxAds = 15;
      } else if (this.currentCompanyPackage.name === "Elite") {
        this.maxAds = 20;
      } else if (this.currentCompanyPackage.name === "Enterprise") {
        this.maxAds = 999;
      }
    },
    checkIfPageIsDisabled() {
      this.disabled = this.formData._vj_disabled;
      const adsEndDate = new Date(this.activeEditionDetails.adverts_end_date)
        .toISOString()
        .slice(0, 10);

      if (this.dateTimeNow > adsEndDate) {
        this.disabled = true;
      }
    },
    prepareData() {
      this.setMaximumAdNumber();
      this.assingData("_vj_disabled", false, "boolean");
      this.assingData("_vj_ads_list", [], "array", true);

      this.formData._vj_ads_list.forEach((adId, index) => {
        this.assingData(`_vj_advertisement_${adId}`, "");
        this.assingData(`_vj_position_${adId}`, "");
        this.assingData(`_vj_category_${adId}`, [], "array");
        this.assingData(`_vj_offer_url_${adId}`, "");
        this.assingData(`_vj_offer_url_checkbox_${adId}`, false, "boolean");
      });
    },
    saveData() {
      this.ignoredKeys = [];
      this.formData._vj_ads_list.forEach((adId, index) => {
        if (this.formData[`_vj_offer_url_checkbox_${adId}`]) {
          this.ignoredKeys.push(`_vj_offer_url_${adId}`);
        } else {
          this.ignoredKeys.push(`_vj_advertisement_${adId}`);
          this.ignoredKeys.push(`_vj_offer_url_checkbox_${adId}`);
        }
      });
      this.sendData(true, "_vj_disabled", this.ignoredKeys);
    },
  },
  mounted() {
    this.prepareData();
    this.checkIfPageIsDisabled();
  },
  computed: {
    ...mapGetters([
      "companyData",
      "companyGuardian",
      "activeEditionOpened",
      "activeEditionDetails",
      "currentCompanyPackage",
      "vuexLoaded",
      "userRole",
    ]),
    dateTimeNow() {
      const dateNow = new Date().toISOString().slice(0, 10);
      return dateNow;
    },
  },
  watch: {
    companyData: function () {
      this.setMaximumAdNumber();
      this.checkIfPageIsDisabled();
    },
    activeEditionDetails() {
      this.checkIfPageIsDisabled();
    },
  },
});
</script>
